import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { draftsApi } from 'src/api/draftsApi';
import { IDraftAssetERC20 } from 'src/types/Asset';
import { IDraftDocument } from 'src/types/Draft';
import { Giftoin } from 'src/types/Giftoin';
// eslint-disable-next-line import/no-cycle
import { RootState, store } from '../store';


const adapter = createEntityAdapter<IDraftDocument>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (draftDoc) => draftDoc.id,
})

const slicer = createSlice({
    name: 'draftsDocs',
    initialState: adapter.getInitialState(),
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
        // bookAdded: giftoinsAdapter.addOne,
        setDraft: adapter.setOne,
        resetDrafts: adapter.removeAll,
        updateDraft: adapter.updateOne,
        deleteDraft: adapter.removeOne,
        addDraft: adapter.addOne,



        // // eslint-disable-next-line consistent-return
        // },
        // deleteAssetERC20: (state, action: PayloadAction<{ coinId: number }>) => {
        //     if (!state.draftDoc) {
        //         console.error("draft not found in local cache")
        //     }
        //     else {
        //         const { coinId } = action.payload;
        //         state.draftDoc.draft.assets.ERC20 = state.draftDoc.draft.assets.ERC20.filter(asset => asset.id !== coinId)
        //     }
        // },
        // updateDraft: () => {

        // }

    },
    extraReducers(builder) {
        builder.addMatcher(
            draftsApi.endpoints.getDrafts.matchFulfilled,
            // Update the local giftoins cache
            (state, action: PayloadAction<IDraftDocument[]>) => {
                // Check if its first time updating cache
                const isConnectedFirstTime = !(Object.keys(state.entities).length > 0);
                if (isConnectedFirstTime) {
                    // First time - setting the giftoins data - overwriting the current empty [] giftoins entities
                    adapter.setMany(state, action.payload);

                } else {
                    // Not first time - updating the current giftoins data saved in cache 
                    const changes: Update<IDraftDocument>[] = action.payload.map(draftDoc => {
                        const entityState: Update<Giftoin> = {
                            id: draftDoc.id,
                            changes: draftDoc,
                        };
                        return entityState
                    })
                    adapter.updateMany(state, changes);
                }

            }

        )
    }

})

export const { updateDraft, resetDrafts, deleteDraft, addDraft } = slicer.actions;

export const draftsDocsSelectors = adapter.getSelectors<RootState>(
    (state) => state.draftsDocs,
)
export const selectDraftDoc = (id: string | EntityId) =>
    createSelector((state: RootState) =>
        draftsDocsSelectors.selectById(state, id), draftDoc => draftDoc)



const globalizedSelectors = adapter.getSelectors<RootState>((state) => state.draftsDocs);

const getOneDraft = (state: any, id: string) => globalizedSelectors.selectById(state, id);


export default slicer.reducer
