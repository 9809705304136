import { useSnackbar } from "notistack";
import { useState } from "react";
import { draftsApi } from "src/api/draftsApi";
import { updateDraft as updateDraftRedux } from "src/redux/slices/draftsDocsSlice";
import { RootState, store, useAppDispatch } from "src/redux/store";
import { IDraftAssetERC20 } from "src/types/Asset";
import { IDraft, IDraftDocument } from "src/types/Draft";
import useCreateDraftServer from './useCreateDraftServer';



export default function useUpdateDraft() {

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { createDraftServer } = useCreateDraftServer();

    async function updateDraft(updatedDraft: IDraftDocument) {
        try {
            setIsLoading(true);



            if (updatedDraft.id === '') {
                updatedDraft.id = await createDraftServer(updatedDraft);
            };

            // Update on server
            const res: any = await dispatch(draftsApi.endpoints.updateDraft.initiate({ product: updatedDraft.draft, draftId: updatedDraft.id }));

            if (res.error) {
                throw Error("Error updating draft with server")
            }
            const { draftId } = res.data;

            enqueueSnackbar("Saved Changes", { variant: 'success' })

            dispatch(updateDraftRedux({ id: updatedDraft.id, changes: updatedDraft }));
            return draftId as string;
            // eslint-disable-next-line no-useless-catch
        } catch (error) {
            throw error;
        }
        finally {
            setIsLoading(false);

        }
    }


    return { updateDraft, isLoading };
}