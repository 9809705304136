import { useSnackbar } from "notistack";
import { useState } from "react";
import { draftsApi } from "src/api/draftsApi";
import { store, useAppDispatch } from "src/redux/store";
import { IDraftDocument } from "src/types/Draft";
import { addDraft as addDraftRedux } from '../../redux/slices/draftsDocsSlice';
import useOpenDraft from "./useOpenDraft";

export default function useDeleteDraft() {

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const deleteDraft = async (draftId: string) => {
        try {
            setIsLoading(true);

            const curDraftId = store.getState().creator.draftDoc?.id;

            if (draftId === curDraftId) {
                enqueueSnackbar("Can't delete file in use ", { variant: 'error' })
                setIsLoading(false);
                return false;
            }


            const res: any = await dispatch(draftsApi.endpoints.deleteDraft.initiate({ draftId }));
            if (res?.data === true) {
                enqueueSnackbar('Successfully delete file')
            }
            else {
                throw new Error('Detailed log in console')
            }
            setIsLoading(false);
            return true;
        } catch (error) {
            console.log(error);
            enqueueSnackbar(`Failed to delete file -  ${error.message}`, { variant: 'error' });
            setIsLoading(false);
            return false;
        }

    }



    return { deleteDraft, isLoading }
}
