import { useState } from 'react';
// @mui
import {
  Stack,
  Avatar,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/components/confirm-dialog';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { fDate } from 'src/utils/formatTime';
import useDoubleClick from 'src/hooks/useDoubleClick';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { IDraftDocument } from 'src/types/Draft';
import ImageIcon from '@mui/icons-material/Image';
import useOpenDraft from 'src/hooks/creator/useOpenDraft';
import { useAppDispatch } from 'src/redux/store';
import { updateOpenFilesDialog } from 'src/redux/slices/modalsSlice';
// import FileDetailsDrawer from '../portal/FileDetailsDrawer';
import useDeleteDraft from 'src/hooks/creator/useDeleteDraft';
import { LoadingButton } from '@mui/lab';
//

// ----------------------------------------------------------------------

type Props = {
  row: IDraftDocument;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function FileTableRow({ row, selected, onSelectRow, onDeleteRow }: Props) {

  const { lastModifiedTimestamp, createdTimestamp, draft } = row;
  const { openDraft } = useOpenDraft();

  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();

  const dispatch = useAppDispatch();


  // const [inviteEmail, setInviteEmail] = useState('');

  // const [openShare, setOpenShare] = useState(false);

  const [openDetails, setOpenDetails] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);


  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);



  // const handleFavorite = () => {
  //   setFavorited(!favorited);
  // };

  const handleOpenDetails = () => {
    // Prevent side menu details per draft
    // setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  // const handleOpenShare = () => {
  //   setOpenShare(true);
  // };

  // const handleCloseShare = () => {
  //   setOpenShare(false);
  // };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    // alert("implement delete draft")
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
    // Close the parent files dialog
  };

  // const handleChangeInvite = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setInviteEmail(event.target.value);
  // };

  const handleOpenDraftInStudio = () => {
    openDraft(row.id);
    dispatch(updateOpenFilesDialog({ show: false }));
  }
  const { deleteDraft, isLoading: isLoadingDelete } = useDeleteDraft();

  const handleDelete = async () => {

    // Local cache and send request to server
    const isSuccess = await deleteDraft(row.id);

    // Delete from table
    if (isSuccess) {
      onDeleteRow();

    }
    handleCloseConfirm();

  }


  const handleClick = useDoubleClick({
    click: () => {
      handleOpenDetails();
    },
    doubleClick: () => console.log('DOUBLE CLICK'),
  });

  const handleCopy = () => {
    enqueueSnackbar('Copied!');
    copy(JSON.stringify(row));
  };


  // Quick fix to prevent load undefined drafts (Alon)
  if (!draft) return <></>;
  const { metadata, network } = draft;
  const { name, images } = metadata;


  return (
    <>
      <TableRow
        sx={{
          borderRadius: 1,
          '& .MuiTableCell-root': {
            bgcolor: 'background.default',
          },
          ...(openDetails && {
            '& .MuiTableCell-root': {
              color: 'text.primary',
              typography: 'subtitle2',
              bgcolor: 'background.default',
            },
          }),
        }}
      >


        {/* Row checkbox */}
        {/* <TableCell
          padding="checkbox"
          sx={{
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        >
          <Checkbox
            checked={selected}
            onDoubleClick={() => console.log('ON DOUBLE CLICK')}
            onClick={onSelectRow}
          />
        </TableCell> */}

        <TableCell onClick={handleClick}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <FileThumbnail file="file" /> */}

            <Typography noWrap variant="inherit" sx={{ maxWidth: 360, cursor: 'pointer' }}>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        {/* <TableCell
          align="left"
          onClick={handleClick}
          sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}
        >
          {fData(size)}
        </TableCell> */}

        <TableCell
          align="left"
          sx={{ color: 'text.secondary', whiteSpace: 'nowrap', textTransform: 'capitalize' }}
        >
          {network}
        </TableCell>
        <TableCell
          align="left"
          onClick={handleClick}
          sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}
        >
          {fDate(createdTimestamp)}
        </TableCell>

        <TableCell
          align="left"
          onClick={handleClick}
          sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}
        >
          {fDate(lastModifiedTimestamp)}
        </TableCell>

        <TableCell align="right" onClick={handleClick}>
          <Stack direction='row'
            sx={{
              '& .MuiAvatarGroup-avatar': {
                width: 24,
                height: 24,
                '&:first-of-type': {
                  fontSize: 12,
                },
              },
            }}
          >
            {images &&
              images.map((image, key) => {
                if (image === "") return <Avatar><ImageIcon /></Avatar>
                return <Avatar key={key} alt={`${name}_image_${key}`} src={image} />;

              })}
          </Stack>
        </TableCell>

        <TableCell
          align="right"
          sx={{
            whiteSpace: 'nowrap',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          {/* <Checkbox
            color="warning"
            icon={<Iconify icon="eva:star-outline" />}
            checkedIcon={<Iconify icon="eva:star-fill" />}
            checked={favorited}
            onChange={handleFavorite}
            sx={{ p: 0.75 }}
          /> */}

          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
      // sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            handleClosePopover();
            handleCopy();
          }}
        >
          <Iconify icon="eva:link-2-fill" />
          Copy JSON Object
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDraftInStudio();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-2-outline" />
          Open
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>

      {/* <FileDetailsDrawer
        item={row}
        open={openDetails}
        onClose={handleCloseDetails}
        onDelete={onDeleteRow}
      /> */}

      {/* <FileShareDialog
        open={openShare}
        shared={shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onCopyLink={handleCopy}
        onClose={() => {
          handleCloseShare();
          setInviteEmail('');
        }}
      /> */}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton loading={isLoadingDelete} variant="contained" color="error" onClick={handleDelete}>
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}
