import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import useOpenDraft from "src/hooks/creator/useOpenDraft";
import { RootState, store, useAppDispatch } from "src/redux/store";
import { PATH_STUDIO } from "src/routes/paths";
import { DraftDocument } from "src/types/Draft";
import { SupportedNetworks } from "src/types/Network";
import useCreateDraftServer from 'src/hooks/creator/useCreateDraftServer';


export default function CreatorStudioButton() {

    const { openDraft } = useOpenDraft();
    const { createDraftServer, isLoading } = useCreateDraftServer();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleOpenCreatorStudio = async () => {
        try {


            // If draft doc exists in cache -> just navigate to creator studio
            // If it doesn't create a new one-> navigate to creator studio
            if (store.getState().creator.draftDoc) {
                navigate(PATH_STUDIO.creator, { replace: true });
            } else {
                // Generate new draft name
                const newDraftName = `Draft #${(store.getState() as RootState).draftsDocs.ids.length}`;
                // Get user wallet address
                const creatorWalletAddress = (store.getState() as RootState).auth.userWeb3.walletAddress as string;

                // Create new draft document
                const newDraft = new DraftDocument(newDraftName, SupportedNetworks.Mumbai, creatorWalletAddress);
                // Create draft on server and receive its id
                const newDraftId = await createDraftServer(newDraft);


                // Open the new draft
                await openDraft(newDraftId);

            }


        } catch (error: any) {
            enqueueSnackbar(error.message ?? "Error creating new draft", { variant: 'error' })
        }

    }

    return <LoadingButton loading={isLoading} onClick={handleOpenCreatorStudio} variant='soft'>Creator Studio</LoadingButton>
}