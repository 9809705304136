

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Giftoin } from 'src/types/Giftoin';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';

// Define a type for the slice state
interface ModalsState {
    notAvailableVersion: boolean;
    help: boolean;
    unwrap: boolean;
    general: IGeneralModalProps;
    giftoinInfo: IGiftoinInfoModalProps;
    giftoinReply: IGiftoinReplyModalProps;
    giftoinMessage: IGiftoinMessageModalProps;
    openFiles: IOpenFilesDialogProps;
}

interface IBaseModalProps {
    show: boolean;
}
interface IOpenFilesDialogProps extends IBaseModalProps {
}

interface IGiftoinMessageModalProps extends IBaseModalProps {
    giftoin: Giftoin | undefined;
}

interface IGiftoinReplyModalProps extends IBaseModalProps {
    sendToEmail: string;
}

interface IGiftoinInfoModalProps extends IBaseModalProps {
    giftoin: Giftoin | undefined;
}


interface IGeneralModalProps extends IBaseModalProps {
    text: string;

}

// Define the initial state using that type
const initialState: ModalsState = {
    notAvailableVersion: false,
    help: false,
    unwrap: false,
    general: {
        show: false,
        text: "",
    },
    giftoinInfo: {
        giftoin: undefined,
        show: false,
    },
    giftoinReply: {
        show: false,
        sendToEmail: '',
    },
    giftoinMessage: {
        show: false,
        giftoin: undefined,
    },
    openFiles: {
        show: false,
    }

}

export const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        updateNotAvailableVersionModal: (state, action: PayloadAction<boolean>) => {
            state.notAvailableVersion = action.payload;

        },
        updateHelpModal: (state, action: PayloadAction<boolean>) => {
            state.help = action.payload;

        },
        updateUnwrapModal: (state, action: PayloadAction<boolean>) => {
            state.unwrap = action.payload;

        },
        updateGeneralModal: (state, action: PayloadAction<IGeneralModalProps>) => {
            state.general = action.payload;

        },
        updateGiftoinInfoModal: (state, action: PayloadAction<IGiftoinInfoModalProps>) => {
            state.giftoinInfo = action.payload;

        },
        updateGiftoinReplyModal: (state, action: PayloadAction<IGiftoinReplyModalProps>) => {
            state.giftoinReply = action.payload;

        },
        updateGiftoinMessageModal: (state, action: PayloadAction<IGiftoinMessageModalProps>) => {
            state.giftoinMessage = action.payload;
        },
        updateOpenFilesDialog: (state, action: PayloadAction<IOpenFilesDialogProps>) => {
            state.openFiles = action.payload;
        },









    },

});

export const { updateNotAvailableVersionModal, updateOpenFilesDialog, updateGiftoinReplyModal, updateGiftoinInfoModal, updateGiftoinMessageModal, updateHelpModal, updateUnwrapModal, updateGeneralModal } = modalsSlice.actions;


export const getNotAvailableVersionModal = createSelector((state: RootState) => state.modals.notAvailableVersion, (notAvailableVersion) => notAvailableVersion
)

export const getHelpModal = createSelector((state: RootState) => state.modals.help, (help) => help
)
export const selectUnwrapModal = createSelector((state: RootState) => state.modals.unwrap, (unwrap) => unwrap
)
export const selectGiftoinReplyModal = createSelector((state: RootState) => state.modals.giftoinReply, (giftoinReply) => giftoinReply
)

export const selectGeneralModal = createSelector((state: RootState) => state.modals.general, (general) => general
)
export const selectGiftoinInfoModal = createSelector((state: RootState) => state.modals.giftoinInfo, (giftoinInfo) => giftoinInfo
)
export const selectGiftoinMessageModal = createSelector((state: RootState) => state.modals.giftoinMessage, (giftoinMessage) => giftoinMessage
)
export const selectOpenFilesDialog = createSelector((state: RootState) => state.modals.openFiles, (openFiles) => openFiles
)







export default modalsSlice.reducer

