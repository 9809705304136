// routes
import { SelectionArea } from 'src/types/Creator';
import Label from 'src/components/label';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} color='white' sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  wrap: icon('ic_wrap_icon_blockchain'),
  assets: icon('ic_asset_icon_blockchain'),
  mint: icon('ic_asset_icon_blockchain'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Assets',
        path: PATH_DASHBOARD.user.root,
        selectionArea: null,
        icon: ICONS.assets,
        children: [
          {
            title: 'ERC20', selectionArea: SelectionArea.AssetsERC20
          },
          {
            title: 'ERC721', selectionArea: SelectionArea.AssetsERC721
          },
        ],
      },
      {
        title: 'Wrap',
        path: PATH_DASHBOARD.user.root,
        selectionArea: null,
        icon: ICONS.wrap,
        children: [
          {
            title: 'Properties', selectionArea: SelectionArea.WrapProperties
          },
          {
            title: 'Design', selectionArea: SelectionArea.WrapDesign
          },
        ],
      },
      {
        title: 'Mint',
        path: PATH_DASHBOARD.user.root,
        selectionArea: null,
        icon: ICONS.mint,
        info: (
          <Label color="info" >
            NEW
          </Label>
        ),

        children: [
          {
            title: 'Image', selectionArea: SelectionArea.MintImage,
          },
          {
            title: 'Greeting Card', selectionArea: SelectionArea.MintGreetingCard
          }

        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: '',
  //   items: [
  //     {
  //       title: 'Blockchain',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.analytics,
  //       children: [
  //         { title: 'Chains', selectionArea: SelectionArea.AssetsERC20 },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   subheader: '',
  //   items: [
  //     {
  //       title: 'Gift',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.analytics,
  //       children: [
  //         { title: 'Category', path: PATH_DASHBOARD.user.four },
  //         { title: 'Expiration date', path: PATH_DASHBOARD.user.four },
  //         { title: 'Quantity', path: PATH_DASHBOARD.user.four },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   subheader: '',
  //   items: [
  //     {
  //       title: 'Cover',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.analytics,
  //       children: [
  //         { title: 'Template', path: PATH_DASHBOARD.user.four },
  //         { title: 'Custom Wrap', path: PATH_DASHBOARD.user.four },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
