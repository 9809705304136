import React, { FC, useEffect } from 'react'
import useCheckIfUserIsConnected from 'src/hooks/auth/useCheckIfUserIsConnected';
import { useCheckIfWalletIsConnect, useWalletListeners } from 'src/hooks/web3/wallet';
import { useAppDispatch } from 'src/redux/hooks';

type AuthLayerProps = {
    children: React.ReactNode;
};

/**
 * Initialize the auth slice 
 * 
 * Detect if the user is already logged in - update the redux slice accordingly:
 * * Detect wallet
 * * Detect firebase auth user
 * 
 * Initiate wallet listeners
 * * When user changes network
 * * When user disconnect changes account on metamask
 * 
 */
export function AuthLayer({ children }: AuthLayerProps) {
    const { checkIfUserIsConnected } = useCheckIfUserIsConnected();
    checkIfUserIsConnected();
    useCheckIfWalletIsConnect();
    useWalletListeners();
    return <>{children}</>;

}

export default AuthLayer