/* eslint-disable import/no-cycle */
import { Txn } from "./Asset";
import { SupportedNetworks } from "./Network";

/** Union of object values as type  */
type ObjectValues<T> = T[keyof T];


export namespace Asset {

    export namespace Firebase {
        export interface IERC20APIExternal {
            amount: number;
            id: number;
            currencyType: Currency.Enums.Type;
            currencyProperties: Currency.Types.ICurrencyProperties;
            storageType: Storage.Enums.Type;
            storageProperties: Storage.Types.IProperties;
        }
        // Global Properties
        export namespace Source {

            export namespace Interfaces {
            }

            export namespace Enums {
                /**
                * Who supplied the asset if any in creation
            */
                export enum Type {
                    Giftoin = "Giftoin",
                    External = "External",
                    None = "None",
                }
            }


        }
        export namespace Storage {

            export namespace Interfaces {
                export interface IBase {
                    // isInternal: boolean;
                }

                export interface IWalletProperties extends IBase {
                    address: string;
                }
                export interface IContractProperties extends IBase {
                    address: string;
                }

                export interface ILiquidityProviderProperties extends IBase {
                    interaction: "API" | "Manual";
                    /** The id of the LP */
                    id: string;
                }
            }

            export namespace Enums {
                /**
               * Who have the asset right now - needed for when unwrapping
               */
                export enum Type {
                    Wallet = "Wallet",
                    Contract = "Contract",
                    LiquidityProvider = "LiquidityProvider",
                }
            }

            export namespace Types {
                export type IProperties = Interfaces.IContractProperties | Interfaces.ILiquidityProviderProperties | Interfaces.IWalletProperties;

            }

        }
        // ERC20
        export namespace Currency {

            export namespace Interfaces {
                export interface IFiatProperties {
                    fiatSymbol: string;
                    amount: number;
                }
                export interface ICryptoProperties {

                }
            }

            export namespace Types {
                export type ICurrencyProperties = Interfaces.ICryptoProperties | Interfaces.IFiatProperties;
            }
            export namespace Enums {
                /**
              * The amount asset is for fiat currency or crypto
              */
                export enum Type {
                    Fiat = "Fiat",
                    Crypto = "Crypto",
                }
            }


        }


        export namespace Enums {

            export const TransferStatus = {
                Pending: "Pending",
                InProgress: "In Progress",
                Delivered: "Delivered",
                Error: "Error"
            } as const;

            export type TransferStatus = ObjectValues<typeof TransferStatus>;

        }



        export interface IBase {
            productAmount: number;
            giftoinAmount: number;
            status: Enums.TransferStatus;
            txn?: Txn;
            storageType: Storage.Enums.Type;
            sourceType: Source.Enums.Type;
            network: SupportedNetworks;
            storageProperties: Storage.Types.IProperties;
        }



        export interface IERC20 extends IBase {
            contractAddress: string;
            tokenName: string;
            tokenSymbol: string;
            isNative: boolean;
            decimals: number;
            id: number;
            network: SupportedNetworks;
            currencyType: Currency.Enums.Type;
            currencyProperties: Currency.Types.ICurrencyProperties;
            conversion: {
                usdToCoin: number;
                coinToUSD: number;
                lastUpdateTimeStamp: number;
            }
        }



        // ERC721

        export interface IERC721 extends IBase {
            tokenId: number;
            contractAddress: string;
            tokenUri?: string;
            price: number;
            metadata: NFTMetadata;
            fiatSymbol: string;
        };

        export interface NFTMetadata extends Partial<{
            image: string;
            name: string;
            description: string;
            external_url: string;
            animation_url: string;
            attributes: { trait_type: string; value: string }[];
            background_color: string;
            youtube_url: string;
            file_type: string;
        }> {

        }
        ;

        // ERC1155

        export interface IERC1155 extends IBase {
            tokenId: number;
            contractAddress: string;
            price: number;
            metadata: ERC1155Metadata;
            fiatSymbol: string;
            tokenUri?: string;
        };

        interface ERC1155Metadata {
            image: string | null;
            name: string | null;
            description: string | null;
            external_link: string | null;
            animation_url: string | null;
            traits: any[];
        };
    }
    /**
 * ERC20 Asset received externally using the public API
 */


}


export namespace Product {
    export namespace Firebase {
        export namespace Enums {
            export enum Visibility {
                Private = "Private",
                Ecommerce = "Ecommerce"
            };

            export const Status = {
                // ValidatingOrder: 'Order Received',
                InCreation: 'In Creation',
                Available: 'Available',
                Error: 'Error',
                OutOfStock: 'Out Of Stock',
                Removed: 'Removed',
                Draft: 'Draft',
            } as const;

            export type Status = ObjectValues<typeof Status>;


            export enum Design {
                Template = "Template",
                Custom = "Custom",
                Default = "default",
            }

        }
        export interface Metadata {
            images: string[];
            name: string;
            description: string;
            logo: string;
            design: Enums.Design;
            category: string;
            type: string;
        }



        export interface IProduct {
            EOL: number;
            SKU: string;
            assets: {
                ERC20: Asset.Firebase.IERC20[];
                ERC721: Asset.Firebase.IERC721[];
                ERC1155: Asset.Firebase.IERC1155[];
            };
            createdTimestamp: number;
            createdTotalWorth: number;
            createdTxn: string | null;
            removedTxn?: string;
            creator: string;
            creatorId: string;
            currentTotalWorth: number;
            currentTotalWorthTime: number;
            deliveredAssets: number;
            giftoins: string[];
            inStock: number;
            maxMargin: number;
            minMargin: number;
            visibility: Enums.Visibility;
            onDemand: boolean;
            quantity: number;
            status: Enums.Status;
            tokenId: number;
            totalAssets: number;
            version: string;
            network: SupportedNetworks;
            metadata: Metadata;
        }

    }
}