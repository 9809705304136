import { initializeApp } from "firebase/app";
import { User } from "firebase/auth";
import { userAuth } from "src/api/userAuth";
import { setSession } from "src/auth/utils";
import { FIREBASE_API } from "src/config-global";
import { updateUserWalletAddress, web2Login } from "src/redux/slices/authSlice";
import { store, useAppDispatch } from "src/redux/store";
import { extractStatusObjectFromReduxResponse } from "src/utils/errors";
// import { DraftDocument } from "src/types/Draft";
// import { SupportedNetworks } from "src/types/Network";
// import useCreateDraftLocal from "../creator/useCreateDraftLocal";
import useCreateNewDraft from "../creator/useCreateNewDraft";





export default function useLogin() {

    const { createNewDraft } = useCreateNewDraft();
    const dispatch = useAppDispatch();
    // eslint-disable-next-line consistent-return
    const login = async (firebaseUser: User) => {
        if (firebaseUser) {
            const firebaseIdToken = await firebaseUser.getIdToken();
            const emailAddress = firebaseUser.email ?? "";

            // Attempt to login with user credentials and fetch its data
            const result: any = await store.dispatch(userAuth.endpoints.login.initiate({ emailAddress }));
            if (result.error) {
                const errorStatus = extractStatusObjectFromReduxResponse(result.error);
                if (errorStatus.errorCode === 500) {
                    throw new Error("Communication error with server - please try again later");
                };
                return false;
            };
            // Save access token in local storage & initiate timer for current session
            setSession(firebaseIdToken);

            const { walletAddress } = result.data;

            // Need to save the user wallet address in local cache because its needed in the creation of a new draft
            dispatch(updateUserWalletAddress(walletAddress));
            // If there isn't an opened draft in cache -> create a new one
            if (!store.getState().creator.draftDoc) {
                await createNewDraft();
            }

            dispatch(web2Login({ user: firebaseUser, walletAddress }));
            return true;

        }

        return false;

    }


    return { login }

}