import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
} from '@reduxjs/toolkit'
import { Giftoin } from 'src/types/Giftoin';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';


const giftoinsAdapter = createEntityAdapter<Giftoin>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (giftoin) => giftoin.publicKey,
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => {
        if (a.giftedDate < b.giftedDate) {
            return 1
        }
        if (a.giftedDate === b.giftedDate) {
            return 0;
        }
        return -1;
    },
})

export const giftoinsSlice = createSlice({
    name: 'giftoins',
    initialState: giftoinsAdapter.getInitialState(),
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
        // bookAdded: giftoinsAdapter.addOne,
        updateGiftoin: giftoinsAdapter.setOne,
        resetGiftoins: giftoinsAdapter.removeAll,
    },
})

export const { updateGiftoin, resetGiftoins } = giftoinsSlice.actions;
// export const giftoinsSelectors = giftoinsAdapter.getSelectors((state: any) => state.giftoins);

/* Can create a set of memoized selectors based on the location of this entity state
   By using the RootState from store we are using the giftoinsSlice created here
   
   This is crucial in order to use 
   */
export const giftoinsSelectors = giftoinsAdapter.getSelectors<RootState>(
    (state) => state.giftoins,
)

/**
 * 
 * @param id 
 * @returns 
 */
//! The create selector needs the root state in order to subscribe to the value
export const selectGiftoin = (id: string | EntityId) => createSelector((state: RootState) => giftoinsSelectors.selectById(state, id), giftoin => giftoin)
export const selectGiftoinsIds = createSelector((state: RootState) => giftoinsSelectors.selectIds(state), ids => ids)



