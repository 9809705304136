// routes
import { PATH_DASHBOARD, PATH_STUDIO } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const STUDIO_SERVER_URL = process.env.REACT_APP_STUDIO_SERVER_URL || '';


export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_STUDIO.creator;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};


// giftoin address
/** When creating product not on polygon/mumbai need to transfer the assets to our wallet and not smart-contract */
export const BRIDGE_UNWRAP_DESTINATION = process.env.REACT_APP_BRIDGE_UNWRAP_DESTINATION ?? "";


// creator studio
export const MAX_ERC721_ASSETS = process.env.REACT_APP_MAX_ERC721_ASSETS ? parseInt(process.env.REACT_APP_MAX_ERC721_ASSETS, 10) : 3;
export const MINT_ERC721_CONTRACT_ADDRESS_GOERLI = process.env.REACT_APP_MINT_ERC721_CONTRACT_ADDRESS_GOERLI ?? "";
export const MINT_ERC721_CONTRACT_ADDRESS_MUMBAI = process.env.REACT_APP_MINT_ERC721_CONTRACT_ADDRESS_MUMBAI ?? "";
export const MINT_ERC721_CONTRACT_ADDRESS_POLYGON = process.env.REACT_APP_MINT_ERC721_CONTRACT_ADDRESS_POLYGON ?? "";

// eslint-disable-next-line radix
export const MAX_PRODUCT_CREATION_QUANTITY = parseInt((process.env.REACT_APP_MAX_PRODUCT_CREATION_QUANTITY ?? "10"));


// template

export const TEMPLATE_PATH = process.env.REACT_APP_TEMPLATE_PATH || 'templates/single_picture';

export const IPFS_GATEWAY = process.env.REACT_APP_IPFS_GATEWAY || "";
