import Box from "@mui/material/Box";
import { useAppDispatch } from "src/redux/hooks";
import { updateSelectionArea } from "src/redux/slices/creatorSlice";
import { SelectionArea } from "src/types/Creator";

interface Props {
    hasChildren: any;
    selectionArea: SelectionArea | null;
    renderContent: any;
}


export default function RenderItem({ hasChildren, selectionArea, renderContent }: Props) {

    const dispatch = useAppDispatch();

    const handleClick = () => {
        // List headers will have null selection area value - prevent updating selection aread
        if (selectionArea === null) return alert("missing selection area");
        dispatch(updateSelectionArea(selectionArea));
        return true;
    }

    // Has child
    if (hasChildren) {
        return renderContent;
    }

    // Default
    return (
        <Box onClick={handleClick}>
            {renderContent}
        </Box>
    );

}