export const truncateAddress = (address: string, startCharsAmount: number, endStartAmount: number, dotsAmount?: number) => {

    if (!address) {
        return "";
    }

    const { length } = address;
    let dots = '';
    for (let index = 0; index < (dotsAmount ?? 3); index += 1) {
        dots += '.'
    }

    return `${address.slice(0, startCharsAmount)}${dots}${address.slice(length - endStartAmount, length)}`;
}
