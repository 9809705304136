// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STUDIO = '/studio';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  drafts: path(ROOTS_DASHBOARD, '/drafts'),
  analytics: path(ROOTS_DASHBOARD, '/analytics'),
  products: path(ROOTS_DASHBOARD, '/products'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
export const PATH_STUDIO = {
  root: ROOTS_STUDIO,
  creator: path(ROOTS_STUDIO, '/creator'),
  three: path(ROOTS_DASHBOARD, '/three'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
