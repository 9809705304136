import { useSnackbar } from "notistack";
import { useState } from "react";
import { draftsApi } from "src/api/draftsApi";
import { dispatch, store, useAppDispatch } from "src/redux/store";
import { DraftDocument, IDraftDocument } from "src/types/Draft";
import { SupportedNetworks } from "src/types/Network";
import { addDraft as addDraftRedux } from '../../redux/slices/draftsDocsSlice';
import useCreateDraftLocal from "./useCreateDraftLocal";
import useCreateDraftServer from "./useCreateDraftServer";
import useOpenDraft from "./useOpenDraft";



/**
 * Use when need to create a fully new draft - both client and server
 * * Create new draft on local
 * * Open it in redux (set as active creator draft)
 * * Save new draft on server -> receive its id 
 * * Fetch all data about new draft from server
 * * Reopen the draft on redux including the new data from server (set as active creator draft)
 * @returns 
 */
export default function useCreateNewDraft() {

    // const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { openDraft } = useOpenDraft();


    const { createDraftServer, isLoading: isLoadingServer } = useCreateDraftServer();
    const { createDraftLocal, } = useCreateDraftLocal();
    const { enqueueSnackbar } = useSnackbar();
    const createNewDraft = async () => {
        try {
            setIsLoading(true);

            // Create new draft on local
            const localDraftDoc = await createDraftLocal();
            // Create draft on server and receive its id
            const newDraftId = await createDraftServer(localDraftDoc);


            // Open the new draft
            await openDraft(newDraftId);

            enqueueSnackbar("Created new file");

        } catch (error: any) {
            enqueueSnackbar(error.message ?? "Error creating new draft", { variant: 'error' })
        }
        finally {
            setIsLoading(false);
        }

    }

    return { createNewDraft, isLoading: isLoading || isLoadingServer };

}

