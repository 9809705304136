import { useState } from "react";
import { draftsApi } from "src/api/draftsApi";
import { openDraft } from "src/redux/slices/creatorSlice";
import { RootState, store, useAppDispatch } from "src/redux/store";
import { DraftDocument, IDraftDocument } from "src/types/Draft";
import { SupportedNetworks } from "src/types/Network";
import { addDraft as addDraftRedux } from '../../redux/slices/draftsDocsSlice';

export default function useCreateDraftLocal() {

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    async function createDraftLocal() {
        try {
            setIsLoading(true);
            const newDraftName = `Product #${Math.floor(Math.random() * 100000)}`;
            // Get user wallet address
            const creatorWalletAddress = (store.getState() as RootState).auth.userWeb3.walletAddress as string;

            // Create new draft document
            const newDraft = new DraftDocument(newDraftName, SupportedNetworks.Mumbai, creatorWalletAddress);

            // Add new created draft to draftsDocs slice -> to be shown in drafts dashboard page


            dispatch(openDraft({ draftDoc: newDraft }));

            // window.location.reload(); //! works but not effective (reloads twice the page cus with one time the state wont change, idk why)
            return newDraft;

            // eslint-disable-next-line no-useless-catch
        } catch (error) {
            throw error;
        }
        finally {
            setIsLoading(false);
        }
    }
    return { createDraftLocal, isLoading };

}