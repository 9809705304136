import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, ListItemText, Link } from '@mui/material';
import RoleBasedGuard from 'src/auth/RoleBasedGuard';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { StyledItem, StyledIcon } from './styles';
import { NavItemProps } from '../types';
import RenderItem from '../RenderItem';

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ item, depth, open, active, ...other }, ref) => {
    const { translate } = useLocales();

    const { title, path, icon, info, children, disabled, caption, roles, selectionArea } = item;

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        {...other}
      >
        {icon && <StyledIcon>{icon}</StyledIcon>}

        <ListItemText
          primary={`${translate(title)}`}
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: active ? 'subtitle2' : 'body2',
          }}
        />

        {info && (
          <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {caption && (
          <Tooltip title={`${translate(caption)}`} arrow>
            <Box component="span" sx={{ ml: 0.5, lineHeight: 0 }}>
              <Iconify icon="eva:info-outline" width={16} />
            </Box>
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            icon={subItem ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill'}
            width={16}
            sx={{ ml: 0.5, flexShrink: 0 }}
          />
        )}
      </StyledItem>
    );

    const renderItem = () =>
    // Default
    (
      <Box>
        {renderContent}
      </Box>
    );



    return <RoleBasedGuard roles={roles}> <RenderItem hasChildren={!!children} selectionArea={selectionArea} renderContent={renderContent} />  </RoleBasedGuard>;
  }
);

export default NavItem;
