// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box, Divider } from '@mui/material';
import { PATH_AUTH } from 'src/routes/paths';
// layouts
import { Link as RouterLink } from 'react-router-dom';
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <LoginLayout title='Welcome To Giftoin Creator Studio'>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to Giftoin Creator Studio</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack>

      </Stack>
      <AuthWithSocial />

      {/* OR Divider */}
      {/* <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>

      <AuthLoginForm /> */}

    </LoginLayout>
  );
}
