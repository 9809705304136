import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { isValidToken } from "src/auth/utils";
import { FIREBASE_API } from "src/config-global";
import { initialize, login } from "src/redux/slices/authSlice";
import { RootState, store, useAppDispatch } from "src/redux/store";
import localStorageAvailable from "src/utils/localStorageAvailable";
// import { DraftDocument } from "src/types/Draft";
// import { SupportedNetworks } from "src/types/Network";
// import useCreateDraftLocal from "../creator/useCreateDraftLocal";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);



export default function useCheckIfUserIsConnected() {


    const dispatch = useAppDispatch();
    /**
 * If access token found we wait until the login process is finished before setting the isInitialize to true
 * 
 * Show loading screen until finished authenticate user
 */
    const checkIfUserIsConnected = async () => {
        try {
            const storageAvailable = localStorageAvailable();

            let accessTokenFound = false;
            const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
            if (accessToken && isValidToken(accessToken)) {
                await onAuthStateChanged(AUTH, async (firebaseUser) => {
                    const { isAuthenticatedWeb2 } = (store.getState() as RootState).auth;
                    if (!isAuthenticatedWeb2) {
                        if (firebaseUser) {
                            const res = await login(firebaseUser);
                            if (!res) {
                                // Failed to login with server - only set the auth to initialized = true
                                dispatch(initialize({}))
                            }
                        }
                    }


                });
                accessTokenFound = true;
            }
            if (!accessTokenFound) {

                dispatch(initialize({}));

            }


        } catch (error) {
            dispatch(initialize({}));
            console.log(error);
        }
    }


    return { checkIfUserIsConnected }

}