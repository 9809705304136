import { useState } from "react";
import { draftsApi } from "src/api/draftsApi";
import { useAppDispatch } from "src/redux/store";
import { IDraftDocument } from "src/types/Draft";
import { addDraft as addDraftRedux } from '../../redux/slices/draftsDocsSlice';
import useOpenDraft from "./useOpenDraft";

export default function useCreateDraftServer() {

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const { openDraft } = useOpenDraft();

    async function createDraftServer(draftDoc: IDraftDocument) {
        try {
            setIsLoading(true);
            const res: any = await dispatch(draftsApi.endpoints.createDraft.initiate({ draft: draftDoc.draft }));

            if (res.error) {
                throw Error("Error creating new draft with server")
            }
            const { draftId } = res.data;

            const newDraftDoc = { ...draftDoc, id: draftId };
            // draftDoc.id = draftId;


            // Add new created draft to draftsDocs slice -> to be shown in drafts dashboard page
            dispatch(addDraftRedux(newDraftDoc));

            // dispatch(openDraft({ draftDoc }));

            await openDraft(draftId);

            // dispatch(updateId({draftId: draftDoc.id}));

            return draftId as string;

            // eslint-disable-next-line no-useless-catch
        } catch (error) {
            throw error;
        }
        finally {
            setIsLoading(false);

        }
    }
    return { createDraftServer, isLoading };

}