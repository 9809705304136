import { updateGeneralModal } from "src/redux/slices/modalsSlice";
import { useAppDispatch } from "../redux/hooks";

const errors = new Map<number, string>();
errors.set(5100, "You must be an holder of a giftoin to log in");
errors.set(5154, "User already exists")
errors.set(3005, "Error unwrapping giftoin - contact support");
errors.set(5151, "You are not the user role you trying to login ");
errors.set(5152, "Id token expired - Please refresh the page ")

export const useHandleError = () => {
    const dispatch = useAppDispatch();

    /** Receive the full error object and extract error message and display it in modal 
     * 
     * @param fallbackMessage in case the error is unknown use this predefined error message
    */
    const handleError = (error: any, fallbackMessage?: string) => {
        let { message } = error;
        if (message) {
            dispatch(updateGeneralModal({
                text: error.message,
                show: true,
            }));
        } else {
            // Try to fetch the error code message from the known errors object
            const { errorCode } = error.data.status;
            // If the error code isn't found or unknown set to undefined as indicator to fall back/default error message
            message = errorCode && errors.get(errorCode) ? errors.get(errorCode) : undefined;
            // If not fallbackMessage was sent with this function resort to default error message
            if (!message) message = fallbackMessage || 'unknown error occurred';

            dispatch(updateGeneralModal({
                text: message,
                show: true,
            }));

        }
    }

    return { handleError };
}