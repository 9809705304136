import { useState, useRef, useEffect, SetStateAction, Dispatch } from 'react';
// @mui
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Box,
    Stack,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
// import { DraftsPage } from 'src/routes/elements';
import { store, useAppDispatch, useAppSelector } from 'src/redux/store';
import { selectOpenFilesDialog, updateOpenFilesDialog } from 'src/redux/slices/modalsSlice';
import { IDraftDocument } from 'src/types/Draft';
import { fTimestamp } from 'src/utils/formatTime';
import { DateRangePicker } from '@mui/lab';
import Iconify from 'src/components/iconify';
import { FileFilterName, FileFilterButton, FileFilterType, FileListView, FileGridView, FileChangeViewButton } from 'src/sections/studio/products/file';
import { useGetDraftsQuery } from 'src/api/draftsApi';
import { useDateRangePicker } from 'src/components/date-range-picker';
import { useSettingsContext } from 'src/components/settings';
import { useTable, getComparator } from 'src/components/table';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

// ----------------------------------------------------------------------

export default function FilesDialog() {


    // Dialog Props
    const open = useAppSelector(selectOpenFilesDialog).show;
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(updateOpenFilesDialog({ show: false }));
    };

    const descriptionElementRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    //--------------------------------------
    // Table props 

    const { data: draftsDocs } = useGetDraftsQuery({});


    const table = useTable({ defaultRowsPerPage: 10, defaultOrderBy: 'lastModifiedTimestamp', defaultOrder: 'desc' });

    const {
        startDate,
        endDate,
        onChangeStartDate,
        onChangeEndDate,
        open: openPicker,
        onOpen: onOpenPicker,
        onClose: onClosePicker,
        onReset: onResetPicker,
        isSelected: isSelectedValuePicker,
        isError,
        shortLabel,
    } = useDateRangePicker(null, null);

    const { themeStretch } = useSettingsContext();

    const [view, setView] = useState('list');

    const [filterName, setFilterName] = useState('');

    const [tableData, setTableData] = useState(draftsDocs ?? []);

    const [filterType, setFilterType] = useState<string[]>([]);

    const [openConfirm, setOpenConfirm] = useState(false);

    const [openUploadFile, setOpenUploadFile] = useState(false);

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filterName,
        // filterType,
        filterStartDate: startDate,
        filterEndDate: endDate,
        isError: !!isError,
    }).filter(draft => {
        if (store.getState().creator.draftDoc?.id === draft.id) {
            return false;
        }
        if (store.getState().creator.draftDoc?.draft === undefined) {
            return false;
        }

        return true;
    }); // Don't show the draft the creator is currently editing

    const dataInPage = dataFiltered.slice(
        table.page * table.rowsPerPage,
        table.page * table.rowsPerPage + table.rowsPerPage
    );

    const isNotFound =
        (!dataFiltered.length && !!filterName) ||
        (!dataFiltered.length && !!filterType) ||
        (!dataFiltered.length && !!endDate && !!startDate);

    const isFiltered = !!filterName || !!filterType.length || (!!startDate && !!endDate);

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
        if (newView !== null) {
            setView(newView);
        }
    };

    const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        table.setPage(0);
        setFilterName(event.target.value);
    };

    const handleChangeStartDate = (newValue: Date | null) => {
        table.setPage(0);
        onChangeStartDate(newValue);
    };

    const handleChangeEndDate = (newValue: Date | null) => {
        table.setPage(0);
        onChangeEndDate(newValue);
    };

    const handleFilterType = (type: string) => {
        const checked = filterType.includes(type)
            ? filterType.filter((value) => value !== type)
            : [...filterType, type];

        table.setPage(0);
        setFilterType(checked);
    };

    const handleDeleteItem = (id: string) => {
        const { page, setPage, setSelected } = table;
        const deleteRow = tableData.filter((row) => row.id !== id);
        setSelected([]);
        setTableData(deleteRow);

        if (page > 0) {
            if (dataInPage.length < 2) {
                setPage(page - 1);
            }
        }

        // TODO: Send delete request to server
    };

    const handleDeleteItems = (selected: string[]) => {
        const { page, rowsPerPage, setPage, setSelected } = table;
        const deleteRows = tableData.filter((row) => !selected.includes(row.id));
        setSelected([]);
        setTableData(deleteRows);

        if (page > 0) {
            if (selected.length === dataInPage.length) {
                setPage(page - 1);
            } else if (selected.length === dataFiltered.length) {
                setPage(0);
            } else if (selected.length > dataInPage.length) {
                const newPage = Math.ceil((tableData.length - selected.length) / rowsPerPage) - 1;
                setPage(newPage);
            }
        }
        // TODO: Send delete request to server
    };

    const handleClearAll = () => {
        if (onResetPicker) {
            onResetPicker();
        }
        setFilterName('');
        setFilterType([]);
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };


    useEffect(() => {
        if (draftsDocs) {
            setTableData(draftsDocs);
        }
    }, [draftsDocs])




    return <Dialog open={open} maxWidth='lg' onClose={handleClose} scroll="body">
        <DialogTitle sx={{ pb: 2 }}>Open a file</DialogTitle>
        <Box sx={{ position: 'relative', width: { xs: '1100px', md: '900px', lg: '1200px' }, overflow: 'unset', height: { xs: '500px', lg: '700px' } }}>
            <Scrollbar>

                <DialogContent dividers >

                    <Stack
                        spacing={2.5}
                        direction={{ xs: 'column', md: 'row' }}
                        alignItems={{ xs: 'flex-end', md: 'center' }}
                        justifyContent="space-between"
                        sx={{ mb: 5 }}
                    >
                        <Stack
                            spacing={1}
                            direction={{ xs: 'column', md: 'row' }}
                            alignItems={{ md: 'center' }}
                            sx={{ width: 1 }}
                        >
                            <FileFilterName filterName={filterName} onFilterName={handleFilterName} />

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                {/* <>
                            <FileFilterButton
                                isSelected={!!isSelectedValuePicker}
                                startIcon={<Iconify icon="eva:calendar-fill" />}
                                onClick={onOpenPicker}
                            >
                                {isSelectedValuePicker ? shortLabel : 'Select Date'}
                            </FileFilterButton>

                            <DateRangePicker
                                variant="calendar"
                                startDate={startDate}
                                endDate={endDate}
                                onChangeStartDate={handleChangeStartDate}
                                onChangeEndDate={handleChangeEndDate}
                                open={openPicker}
                                onClose={onClosePicker}
                                isSelected={isSelectedValuePicker}
                                isError={isError}
                            />
                        </> */}

                                {/* <FileFilterType
                            filterType={filterType}
                            onFilterType={handleFilterType}
                            optionsType={FILE_TYPE_OPTIONS}
                            onReset={() => setFilterType([])}
                        />
 */}
                                {isFiltered && (
                                    <Button
                                        variant="soft"
                                        color="error"
                                        onClick={handleClearAll}
                                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                                    >
                                        Clear
                                    </Button>
                                )}
                            </Stack>
                        </Stack>

                        {/* //! Removed view change for now */}
                        {/* <FileChangeViewButton value={view} onChange={handleChangeView} /> */}
                    </Stack>

                    {view === 'list' ? (
                        <FileListView
                            table={table}
                            tableData={tableData}
                            dataFiltered={dataFiltered}
                            onDeleteRow={handleDeleteItem}
                            isNotFound={isNotFound}
                            onOpenConfirm={handleOpenConfirm}
                        />
                    ) : (
                        <FileGridView
                            table={table}
                            data={tableData}
                            dataFiltered={dataFiltered}
                            onDeleteItem={handleDeleteItem}
                            onOpenConfirm={handleOpenConfirm}
                        />
                    )}
                </DialogContent>
            </Scrollbar>
        </Box>
        {/* <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>

            <Button variant="contained" onClick={handleClose}>
                Subscribe
            </Button>
        </DialogActions> */}
    </Dialog >
}

function applyFilter({
    inputData,
    comparator,
    filterName,
    // filterType,
    filterStartDate,
    filterEndDate,
    isError,
}: {
    inputData: IDraftDocument[];
    comparator: (a: any, b: any) => number;
    filterName: string;
    // filterType: string[];
    filterStartDate: Date | null;
    filterEndDate: Date | null;
    isError: boolean;
}) {
    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        inputData = inputData.filter(
            (draft) => draft.draft.metadata.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        );
    }

    // Filter with number of types
    // if (filterType.length) {
    //     inputData = inputData.filter((file) => filterType.includes(fileFormat(file.type)));
    // }

    if (filterStartDate && filterEndDate && !isError) {
        inputData = inputData.filter(
            (file) =>
                fTimestamp(file.createdTimestamp) >= fTimestamp(filterStartDate) &&
                fTimestamp(file.createdTimestamp) <= fTimestamp(filterEndDate)
        );
    }

    return inputData;
}
