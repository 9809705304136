/* eslint-disable consistent-return */
import detectEthereumProvider from '@metamask/detect-provider';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { networkApi } from 'src/api/networkApi';
import { logout, web3Connected } from 'src/redux/slices/authSlice';
import { updateGeneralModal } from 'src/redux/slices/modalsSlice';
import { RootState, store, useAppDispatch, useAppSelector } from 'src/redux/store';
import { Network } from 'src/types/Network';
import { ReturnObject } from 'src/types/org-logger/ReturnObject';
import { useHandleError } from '../useHandleError';
import { getProvider } from './utils';


export function useCheckForProviders() { // Make sure the client has only one provider and if doesn't it alert about it.
    const { enqueueSnackbar } = useSnackbar();
    const checkForProviders = useCallback(async () => { // Make sure the client has only one provider and if doesn't it alert about it.
        try {
            const provider = await detectEthereumProvider();
            if (provider) {
                // If the provider returned by detectEthereumProvider is not the same as
                // window.ethereum, something is overwriting it, perhaps another wallet.
                if (provider !== (window as any).ethereum) {
                    enqueueSnackbar('Do you have multiple wallets installed?', { variant: 'error' });
                    return false;
                }
                return true;
            }
            enqueueSnackbar('Please install MetaMask!', { variant: 'error' });
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }

    }, [enqueueSnackbar])



    useEffect(() => {
        checkForProviders();
    }, [checkForProviders]);


    return <></>

}

/**
 * Listen to events from the wallet provider 
 * 
 * * Refresh the page after changing network
 */
export function useWalletListeners() {

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const isAuthenticatedWeb3 = useAppSelector(state => state.auth.isAuthenticatedWeb3);
    const { getChainData } = useGetChainData();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleChainChanged = useCallback(async () => {
        try {
            const { ethereum } = window as any;

            const provider = getProvider(ethereum);
            if (!provider) return;
            const { chainId, name } = await provider.getNetwork();

            const network = await getChainData(chainId, name);
            const walletAddress = store.getState().auth.userWeb3?.walletAddress;
            if (network && walletAddress) {
                // Reconnect web3 details
                dispatch(web3Connected({ network, walletAddress }));
            }

        } catch (error) {
            console.log(error);
        }
    }, [dispatch, getChainData])

    const handleAccountsChanged = useCallback((accounts: string[]) => {
        const { walletAddress, network, isDisconnected } = (store.getState() as RootState).auth.userWeb3;

        // Prevent automatic relogin after user requested to disconnect 
        if (!isDisconnected) {
            // If connected account is matching the user's wallet address then auto connect 


            if (accounts && (accounts[0]).toLocaleLowerCase() === walletAddress && walletAddress) {
                dispatch(web3Connected({ network: network as Network, walletAddress }));
            }
            else {
                // ! Removed - annoying the user
                // enqueueSnackbar("Incorrect wallet connected. Please connect with your user wallet", { variant: 'error' });
            }
        }

    }, [dispatch]);

    useEffect(() => {
        const { ethereum } = window as any;

        // If client doesn't have ethereum don't use ethereum events
        if (!ethereum) return;
        if (isAuthenticatedWeb3) {
            // It is strongly recommend reloading the page on chain changes - by metamask docs
            ethereum.on('chainChanged', (chainId: number) => handleChainChanged());
            ethereum.on('accountsChanged', (accounts: string[]) => handleAccountsChanged(accounts));

        }
    }, [handleAccountsChanged, handleChainChanged, isAuthenticatedWeb3])


}
// feat: improved metamask alerts

// correct wallet address



/**
 * Use the chain id received by the provider to request the network data from server
 * 
 * @returns Network object from server
 */
function useGetChainData() {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();


    async function getChainData(chainId: number, chainName: string) {
        try {
            const resultNetwork: any = await dispatch(networkApi.endpoints.network.initiate({ chainId }));
            if (resultNetwork.error?.status === 404) {
                enqueueSnackbar(`${chainName} network not supported. Please change network`, { variant: 'error' })
                return null;
            }
            if (!resultNetwork.data) {
                enqueueSnackbar(`Error fetching network data - contact support`, { variant: 'error' })
                return null;

            }
            return resultNetwork.data[0] as Network;
        } catch (error) {
            enqueueSnackbar(`Error fetching network data - contact support`, { variant: 'error' })
            throw error;
        }
    }

    return { getChainData }
}


/**
 * Check if wallet is connected and fetch necessary data 
 * 
 * *Chain id 
 * 
 * *Current account
 * 
 */
export function useCheckIfWalletIsConnect() {
    // const { userLogin } = useUserLogin(undefined);
    const dispatch = useAppDispatch();
    const { getChainData } = useGetChainData();
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Check for wallet only after user has connected with web2
     */
    const isAuthenticatedWeb2 = useAppSelector(state => state.auth.isAuthenticatedWeb2);
    const { isDisconnected } = (store.getState() as RootState).auth.userWeb3;
    /**
     * Attempts to auto connect user's wallet 
     * 
     * *Checks if the selected network is supported 
     * 
     * *Checks if the connected wallet is the user's wallet
     */
    const checkIfWalletIsConnect = useCallback(
        async () => {
            try {
                const { ethereum } = window as any;

                if (!ethereum) {
                    enqueueSnackbar("Please install MetaMask.", { variant: 'error' });
                    return false;
                }
                // Prevent autologin when the user disconnected
                if (isDisconnected) return;
                // const provider = new ethers.providers.Web3Provider(ethereum, 'any');
                const provider = getProvider(ethereum);
                if (!provider) return;

                const { chainId, name } = await provider.getNetwork();


                // Fetch user's wallet network data from our server
                const currentNetwork = await getChainData(chainId, name);

                if (currentNetwork) {
                    // Check if we have access to user's accounts
                    const accounts = await ethereum.request({ method: "eth_accounts" });
                    const walletAddress = store.getState().auth.userWeb3?.walletAddress;

                    // If connected account is matching the user's wallet address then auto connect 
                    if (accounts && accounts[0] === walletAddress && walletAddress) {
                        dispatch(web3Connected({ network: currentNetwork, walletAddress }));
                    }
                    else {
                        // ! Annoying the user
                        // enqueueSnackbar("Incorrect wallet connected. Please connect with your user wallet", { variant: 'error' });
                    }
                    return true;
                }

            } catch (error) {
                console.log(error);
                return false;
            }
        }
        ,
        [dispatch, enqueueSnackbar, getChainData, isDisconnected],
    )

    useEffect(() => {
        if (isAuthenticatedWeb2) {
            checkIfWalletIsConnect();
        }
    }, [checkIfWalletIsConnect, isAuthenticatedWeb2])
}


/**
 * Initiate the metamask login prompt
 * 
 * Validates:
 * * If the connected wallet is not the one attached to account - an error will appear
 * * If the network selected is supported by us
 */
export function useConnectWallet() {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    // const navigate = useNavigate();


    // Prompt the user the connect meta mask wallet - if its not matching the wallet address received from the DB, alert an error
    const connectWallet = async () => {
        try {
            const { ethereum } = window as any;

            // Check if metamask is installed
            if (!ethereum) {
                enqueueSnackbar("Please install MetaMask.", { variant: 'error' });
                return false;
            }

            // Make sure the user is indeed connected
            if (!store.getState().auth) return alert("Please login with your Google account before login with the wallet")



            // Fetch the wallet address saved in local cache
            const walletAddress = store.getState().auth.userWeb3?.walletAddress;

            if (!walletAddress) {
                enqueueSnackbar("Error occurred logging wallet - please refresh page", { variant: 'error' })
                return false;
            }

            // Request the user to connect to the specific wallet attached to his account
            const accounts = await ethereum.request({ method: "eth_requestAccounts", params: [{ eth_accounts: { walletAddress } }] });

            // Check if the metamask is locked or the user has not connected any accounts
            if (!accounts || accounts.length === 0) {
                dispatch(logout());
                enqueueSnackbar('Please connect to MetaMask.')
                return false;
            }

            // Verify on client side the connected wallet is indeed the correct one
            if (walletAddress !== accounts[0]) {
                enqueueSnackbar("Connected with incorrect wallet, please go to connected sites in metamask to disconnect and then try again with different one.", { variant: 'error', persist: true })
                // navigate('creator');
                return false;
            }



            // Get the connected network chain id 
            const provider = new ethers.providers.Web3Provider(ethereum, 'any');
            const { chainId, name } = await provider.getNetwork()

            // Dispatching an api call to validate the current network and get its name + userSlice automatically save the response
            const response = await dispatch(networkApi.endpoints.network.initiate({ chainId }));

            if (response.isError) {
                // Extract the error message from the base redux query error
                // TODO: Create a function to automatically handle this kind of situation and return the status object
                let error;
                if (response.error as FetchBaseQueryError) {
                    error = ((response.error as FetchBaseQueryError).data as ReturnObject).status;
                }
                enqueueSnackbar(`${name} network not supported. Please change network`, { variant: 'error' })
                return false;
            }

            // In case of empty data object - can't verify the current network is supported 
            if (!response.data) {
                enqueueSnackbar("Error fetching current network from server - Contact Support", { variant: 'error' })
                return false;
            }
            const network = response.data[0];
            dispatch(web3Connected({ network, walletAddress }));
            return true;




        } catch (error: any) {
            console.log(error);
            if (error.code === 4001) {
                // EIP-1193 userRejectedRequest error
                // alert(error.message);
                enqueueSnackbar("User rejected request", { variant: 'info' })

            }
            if (error.code === -32002) {
                enqueueSnackbar("Check metamask for open dialog", { variant: 'warning' })
            }
            return false;

        }


    }

    return { connectWallet };

}


export function useUserLogin() {
    const userWeb3 = useAppSelector(state => state.auth.userWeb3);
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const userLogin = async (accounts: string[]) => {
        if (!accounts || accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            dispatch(logout());
            console.log('Please connect to MetaMask.');
        } else if (accounts[0] !== userWeb3?.walletAddress) {
            const { ethereum } = window as any;
            if (!ethereum) {
                enqueueSnackbar('Please install metamask');
            }
            const provider = new ethers.providers.Web3Provider(ethereum, 'any');

            const { chainId } = await provider.getNetwork()

            // Dispatching an api call to validate the current network and get its name + userSlice automatically save the response
            const resultNetwork = await dispatch(networkApi.endpoints.network.initiate({ chainId }));
            if (resultNetwork.isError) {
                let error;
                if (resultNetwork.error as FetchBaseQueryError) {
                    error = ((resultNetwork.error as FetchBaseQueryError).data as ReturnObject).status;
                }
                enqueueSnackbar(`Error occurred - ${error?.errorMessage}`, { variant: 'error' });
            }

        }

    }

    return { userLogin };
}


/**
 * Used to prompt the user to select any one of his wallets from metamask
 * 
 * Usually used when registering
 * 
 */
export function useSelectWalletAccount() {
    const { handleError } = useHandleError();
    const [account, setAccount] = useState<string | undefined>(undefined);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const selectWalletAccount = async () => {
        setIsLoading(true);
        try {
            const { ethereum } = window as any;

            if (!ethereum) {
                throw Error("Please install metamask");
            }
            const isUnlocked = (await ethereum._metamask.isUnlocked()) as boolean;
            if (!isUnlocked) {
                enqueueSnackbar("Please login to your metamask extension first", { variant: 'warning' });

            }

            const { caveats } = (await ethereum.request({
                method: "wallet_requestPermissions",
                params: [{ eth_accounts: {} }],
            }))[0];

            const accounts = caveats[0].value;


            if (accounts.length > 1) {
                // Alert the user the default is the first account 
                // Don't throw error to prevent going back a step to choosing a wallet
                enqueueSnackbar('You have selected more than 1 account - First account is being used', { variant: 'warning' })
            }
            setAccount(accounts[0]);
            setIsLoading(false);

            return accounts[0] as string;
        } catch (err: any) {
            setIsError(true);
            console.log(err);
            if (err.code === -32002) {
                handleError(new Error("Metamask dialog already open"));
            }
            handleError(err);
            return false;

        }


    }

    return { selectWalletAccount, account, isLoading, error, isError };


}

