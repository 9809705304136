// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// utils
import Label from 'src/components/label';
import { store, useAppSelector } from 'src/redux/store';
import { SupportedNetworks } from 'src/types/Network';
import ChainDropDown from 'src/sections/studio/ChainDropDown';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { LoadingButton } from '@mui/lab';
import useUpdateDraft from 'src/hooks/creator/useUpdateDraft';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useState } from 'react';
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import WalletPopover from './WalletPopover';
import FilePopover from './FilePopover';
import FilesDialog from './FilesDialog';
// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const curNetwork = useAppSelector(state => state.creator.draftDoc?.draft.network);


  const [openFilesDialog, setOpenFilesDialog] = useState(false);



  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {/* //! Currently doesn't have usage for search bar in creator  */}
      {/* <Searchbar /> */}

      <Stack direction='row' spacing={1}>
        <FilePopover />
        <RedirectButton path={PATH_DASHBOARD.products} label='My Products' />
        <SaveButton />
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}
        {(curNetwork === SupportedNetworks.Mumbai || curNetwork === SupportedNetworks.Goerli) && <Label color='info' >Test Network</Label>}
        {(curNetwork === SupportedNetworks.Polygon || curNetwork === SupportedNetworks.Ethereum) && <Label color='success' >Mainnet</Label>}

        <ChainDropDown disabled={false} name={curNetwork ?? ''} />
        <WalletPopover />
        <AccountPopover />
      </Stack>

      <FilesDialog />
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}



/**
 * Use for navigating the user to other page using a simple button
 * @param param0 
 * @returns 
 */
function RedirectButton({ path, label }: { path: string, label: string }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(path)

  }

  return <Button onClick={handleClick} variant='soft' color="inherit"
  > {label}</Button >

}



function SaveButton() {
  const { updateDraft, isLoading } = useUpdateDraft();
  const handleClick = () => {
    const { draftDoc } = store.getState().creator;
    if (draftDoc) {
      updateDraft(draftDoc);
    };
  };

  return <LoadingButton startIcon={<SaveOutlinedIcon />} type="submit" variant="soft" color='primary' loading={isLoading} onClick={handleClick}>
    Save
  </LoadingButton>

}

