import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useSnackbar } from "notistack"
import { FIREBASE_API } from "src/config-global";
// import { login } from "src/redux/slices/authSlice";
import { store } from "src/redux/store";
// import { DraftDocument } from "src/types/Draft";
// import { SupportedNetworks } from "src/types/Network";
// import useCreateDraftLocal from "../creator/useCreateDraftLocal";
import useCreateNewDraft from "../creator/useCreateNewDraft";
import useLogin from "./useLogin";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();


export default function useLoginWithGoogle() {

    // const { createDraftLocal, isLoading } = useCreateDraftLocal();

    const { enqueueSnackbar } = useSnackbar();
    const { createNewDraft } = useCreateNewDraft();

    const { login } = useLogin();

    // eslint-disable-next-line consistent-return
    const loginWithGoogle = async () => {
        try {
            const { user: firebaseUser } = await signInWithPopup(AUTH, GOOGLE_PROVIDER);

            const isSuccess = await login(firebaseUser);

            if (!isSuccess) {
                enqueueSnackbar(`User not found`, { variant: 'warning' })
                return false;
            };

            const walletAddress = store.getState().auth.userWeb3.walletAddress;

            if (!walletAddress) {
                enqueueSnackbar('Wallet Address was not found.', { variant: 'error' });
                return false;
            };

            // const newDraft = new DraftDocument(`Draft #${Math.floor(Math.random() * 100000)}`, SupportedNetworks.Mumbai, walletAddress);

            // const draft = await createDraftLocal();


            // ! Moved to general useLogin hook
            // // If there isn't an opened draft in cache -> create a new one
            // if (!store.getState().creator.draftDoc) {
            //     await createNewDraft();
            // }
            return true;

        }
        catch (error) {
            enqueueSnackbar(`Failed to login -${error.message} `, { variant: 'error' })
        }

    }


    return { loginWithGoogle }

}