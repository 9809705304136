
import { configureStore } from '@reduxjs/toolkit';
import {
    persistReducer,
    persistStore,
} from 'redux-persist'
import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector,
} from 'react-redux';

import { baseStudioServer } from 'src/api/baseStudioServer';
// eslint-disable-next-line import/no-cycle
import rootReducer, { rootPersistConfig } from './rootReducer';
// eslint-disable-next-line import/no-cycle

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat(baseStudioServer.middleware),

})
const persistor = persistStore(store);

const { dispatch } = store;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useAppDispatch = () => useDispatch<AppDispatch>();

export { store, persistor, dispatch, useAppSelector, useAppDispatch };

