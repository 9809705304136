import { Navigate, useRoutes } from 'react-router-dom';
// auth
import StudioLayout from 'src/layouts/studio';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  PageOne,
  AnalyticsPage,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  RegisterPage,
  PageThree,
  CreatorPage,
  // DraftsPage,
  ProductsPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/studio',
      element: (
        <AuthGuard>
          <StudioLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/studio/creator' replace />, index: true },
        {
          path: 'creator',
          element: <CreatorPage />,
        },

      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'drafts', element: <DraftsPage /> },
        { path: 'analytics', element: <AnalyticsPage /> },
        { path: 'products', element: <ProductsPage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
