import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
// components
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
//

// ----------------------------------------------------------------------

// @mui
import { Stack, Box, Button, ButtonProps, Typography, Divider, MenuItem } from '@mui/material';
import SvgColor from 'src/components/svg-color';
import { useNavigate } from 'react-router';
import WalletButton from 'src/sections/header/WalletButton';
import { useAppSelector } from 'src/redux/store';
import { useAppDispatch } from 'src/redux/hooks';
import { web3Disconnected } from 'src/redux/slices/authSlice';

// ----------------------------------------------------------------------

interface Props extends ButtonProps {
    children?: React.ReactNode;
    isSelected: boolean;
}


const OPTIONS = [
    {
        label: 'Home',
        linkTo: '/',
    },
    {
        label: 'Profile',
        linkTo: '/',
    },
    {
        label: 'Settings',
        linkTo: '/',
    },
];


function WalletConnectButton() {

    return <>
    </>
}



export default function WalletPopover() {
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const navigate = useNavigate();
    const { isAuthenticatedWeb3 } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleClickItem = (path: string) => {
        handleClosePopover();
        navigate(path);
    };


    const handleWalletDisconnect = async () => {
        try {
            handleClosePopover();
            await dispatch(web3Disconnected({}));
            return true;

        } catch (error) {
            console.log(error);
            return false;
        }
    }



    return (
        <>

            <WalletButton isSelected={!!openPopover} onClick={handleOpenPopover} />

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 170, p: 0 }}>
                {/* <Stack sx={{ p: 1 }}>
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} /> */}

                <MenuItem onClick={handleWalletDisconnect} sx={{ m: 1, color: 'red' }}>
                    Disconnect
                </MenuItem>
            </MenuPopover>
        </>
    );
}
