

import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit'
import { AssetStatus, IAssetTransfer } from 'src/types/Asset';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
// eslint-disable-next-line import/no-cycle


const adapter = createEntityAdapter<IAssetTransfer>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (asset) => asset.id,
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => {
        if (a.id < b.id) {
            return 1
        }
        if (a.id === b.id) {
            return 0;
        }
        return -1;
    },
})

export const slice = createSlice({
    name: "assetsTransfer",
    initialState: adapter.getInitialState(),
    reducers: {
        updateAssetTransfer: adapter.updateOne,
        setAssetsTransfer: adapter.setMany,

        // updateAssetTransfer: (state, action: PayloadAction<{ Update<Asset> }>) => {
        //     const { assetStatus, assetId } = action.payload;
        //     adapter.updateOne(state, { id: assetId, changes: { status: assetStatus } })

        // },
    },

});


// actions

export const { updateAssetTransfer, setAssetsTransfer } = slice.actions;

export const assetsTransferSelectors = adapter.getSelectors<RootState>(
    (state) => state.assetsTransfer,
)




export default slice.reducer

