import errorsFile from '../assets/errors.json';

const errors = JSON.parse(JSON.stringify(errorsFile));

export class ErrorStatus {
    errorCode: number;

    errorMessage: string;

    constructor(errorCode: number, errorMessage: string) {
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }

}

export const extractStatusObjectFromReduxResponse = (res: any) => {
    const errorCode = res?.data?.status?.errorCode;
    const error = errors[errorCode];

    const message: string = error ? error.message ?? "Unknown Error" : "Unknown Error";

    return new ErrorStatus(errorCode, message);
}


export const extractError = (err: any) => {
    // Error from api request to our services
    if (err?.data.status?.errorCode) {
        return extractStatusObjectFromReduxResponse(err);
    }

    return JSON.stringify(err);
}