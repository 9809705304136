import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { baseStudioServer } from 'src/api/baseStudioServer';
// eslint-disable-next-line import/no-cycle
import { persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-cycle
import authSlice from './slices/authSlice';
// eslint-disable-next-line import/no-cycle
import assetsTransfer from './slices/assetsTransferSlice';
// eslint-disable-next-line import/no-cycle
import { giftoinsSlice } from './slices/giftoinsSlice';
// eslint-disable-next-line import/no-cycle
import { modalsSlice } from './slices/modalsSlice';
// eslint-disable-next-line import/no-cycle
import coinSlice from './slices/coinSlice';
import creatorSlice from './slices/creatorSlice';
// eslint-disable-next-line import/no-cycle
import draftsDocsSlice from './slices/draftsDocsSlice';
// slices

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};
export const creatorPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};


const creatorPersistedReducer = persistReducer(creatorPersistConfig, creatorSlice);

const rootReducer = combineReducers({
  giftoins: giftoinsSlice.reducer,
  modals: modalsSlice.reducer,
  assetsTransfer,
  coins: coinSlice,
  draftsDocs: draftsDocsSlice,
  auth: authSlice,
  creator: creatorPersistedReducer,
  [baseStudioServer.reducerPath]: baseStudioServer.reducer,
});

export default rootReducer;
