import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { STUDIO_SERVER_URL } from 'src/config-global';
import { prepareHeaders } from './utils';

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseStudioServer = createApi({
    reducerPath: 'studioServer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${STUDIO_SERVER_URL}/`, prepareHeaders
    }),
    tagTypes: ['Drafts', 'Draft', 'Product', 'Products', 'Coins', 'NFTs', 'AssetsERC20', 'AssetsERC721', 'Mint', 'User', 'Contract', 'Network'],
    endpoints: () => ({}),

})