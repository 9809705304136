
export interface Network {
    networkName: SupportedNetworks;
    chainId: number;
}

export enum SupportedNetworks {
    Mumbai = "mumbai",
    Goerli = "goerli",
    Polygon = "polygon",
    Ethereum = 'ethereum',
}
