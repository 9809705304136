


export enum CreatorMode {
    Draft = "Draft",
    Creation = "Creation",
}

export enum SelectionArea {
    AssetsERC20,
    AssetsERC721,
    WrapProperties,
    WrapDesign,
    Assets,
    Creation,
    MintImage,
    MintGreetingCard,
}
