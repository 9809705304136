import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { IERC20Coin } from 'src/types/Coin';


const adapter = createEntityAdapter<IERC20Coin>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (coin) => coin.id,
})

export const slice = createSlice({
    name: 'coins',
    initialState: adapter.getInitialState(),
    reducers: {
    },
    // extraReducers(builder) {
    //     builder.addMatcher(
    //         coinApi.endpoints.coins.matchFulfilled,
    //         // Update the local giftoins cache
    //         (state, action: PayloadAction<Coin[]>) => {
    //             // Check if its first time updating cache
    //             const isConnectedFirstTime = !(Object.keys(state.entities).length > 0);
    //             if (isConnectedFirstTime) {
    //                 // First time - setting the giftoins data - overwriting the current empty [] giftoins entities
    //                 adapter.setMany(state, action.payload);

    //             } else {
    //                 // Not first time - updating the current giftoins data saved in cache 
    //                 const changes: Update<Coin>[] = action.payload.map(coin => {
    //                     const entityState: Update<Coin> = {
    //                         id: coin.id,
    //                         changes: coin,
    //                     };
    //                     return entityState
    //                 })
    //                 adapter.updateMany(state, changes);
    //             }

    //         }
    //     )
    // },

})





export default slice.reducer
