import { IDraftAssetERC1155, IDraftAssetERC20, IDraftAssetERC721 } from "./Asset";
import { SupportedNetworks } from "./Network";
import { ProductDesign } from "./ProductDesign";
import { Product } from "./types";

export interface IDraft {
    version: string;
    network: SupportedNetworks;
    creator: string;
    creatorId: string;
    assets: {
        ERC20: IDraftAssetERC20[];
        ERC721: IDraftAssetERC721[];
        ERC1155: IDraftAssetERC1155[];
    }
    metadata: {
        images: string[];
        logo: string;
        name: string;
        design: Product.Firebase.Enums.Design;
        category: string;
        type: string;
        description: string;
    }
    quantity: number;
    EOL: number;
    minMargin: number;
    maxMargin: number;
}


export interface IDraftDocument {
    draft: IDraft,
    id: string;
    creatorId: string;
    createdTimestamp: number;
    lastModifiedTimestamp: number;
}





export class DraftDocument implements IDraftDocument {
    draft: {
        version: string;
        network: SupportedNetworks;
        creator: string;
        creatorId: string;
        assets: {
            ERC20: IDraftAssetERC20[];
            ERC721: IDraftAssetERC721[];
            ERC1155: IDraftAssetERC1155[];
        }
        metadata: {
            images: string[];
            logo: string;
            name: string;
            design: Product.Firebase.Enums.Design;
            category: string;
            type: string;
            description: string;
        }
        quantity: number;
        EOL: number;
        minMargin: number;
        maxMargin: number;
    };

    id: string;

    creatorId: string;

    createdTimestamp: number;

    lastModifiedTimestamp: number;


    constructor(name: string, network: SupportedNetworks, creatorWalletAddress: string) {
        this.draft = {
            version: '1',
            network,
            creator: creatorWalletAddress,
            creatorId: "",
            assets: {
                ERC20: [],
                ERC721: [],
                ERC1155: [],
            },
            metadata: {
                images: ["", "", "", ""],
                logo: '',
                name,
                design: Product.Firebase.Enums.Design.Default,
                category: 'birthday gifts',
                type: 'Box',
                description: 'Product Description'
            },
            quantity: 1,
            // EOL: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime(), //! one year from now, better stay 0 and check if 0 then ignore
            EOL: 0,
            minMargin: 0,
            maxMargin: 0
        };
        this.id = '';
        this.creatorId = '';
        this.createdTimestamp = Date.now();
        this.lastModifiedTimestamp = Date.now();
    }
}