import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { FIREBASE_API } from "src/config-global";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);


export const prepareHeaders = async (headers: Headers, { getState }: { getState: any }) => {


    const token = await AUTH.currentUser?.getIdToken();

    if (token) {
        headers.set('x-giftoin-id-token', token)
    } else {
        console.log("no token available")
    }
    return headers
}
