import { ButtonProps, Button, Box } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useConnectWallet } from "src/hooks/web3/wallet";
import { useAppSelector } from "src/redux/store";
import { truncateAddress } from "src/utils/formatString";

interface Props extends ButtonProps {
    children?: React.ReactNode;
    isSelected: boolean;
}

export default function WalletButton({ children, isSelected, onClick, ...other }: Props) {

    const { connectWallet } = useConnectWallet();
    // Connect wallet function - make sure correct wallet address 
    const handleConnectWallet = async () => {
        try {
            await connectWallet();
        } catch (error) {
            alert("Error connecting wallet")
            console.log(error);
        }
    }
    const { isAuthenticatedWeb3 } = useAppSelector(state => state.auth);

    return <>
        {
            isAuthenticatedWeb3 ? <ConnectedView onClick={onClick} isSelected={isSelected} /> : <DisconnectedView onClick={handleConnectWallet} isSelected={isSelected} />
        }
    </>
}

interface IViewProps {
    onClick: any;
    isSelected: boolean;
}

function ConnectedView({ onClick, isSelected }: IViewProps) {
    // Fetch wallet address from auth context
    const walletAddress = useAppSelector(state => state.auth.userWeb3?.walletAddress) as string;


    const walletAddressTruncated = truncateAddress(walletAddress, 4, 4);

    return <Button
        variant="soft"
        onClick={onClick}
        color="inherit"
        sx={{
            // textTransform: 'unset',
            // color: 'text.secondary',
            width: { xs: 'auto', md: 'auto' },
            justifyContent: 'flex-start',
            fontWeight: 'fontWeightMedium',
        }}
        startIcon={
            <Box
                component="img"
                src="/assets/icons/providers/wallet/Wallet_Provider_Metamask.svg"
            />
        }
        endIcon={
            <Iconify icon={isSelected ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        }
    >
        {walletAddressTruncated}
    </Button>
}

function DisconnectedView({ onClick, isSelected }: IViewProps) {
    return <Button
        variant="soft"
        onClick={onClick}
        color="inherit"
        sx={{
            // textTransform: 'unset',
            // color: 'text.secondary',
            width: { xs: 'auto', md: 'auto' },
            justifyContent: 'flex-start',
            fontWeight: 'fontWeightMedium',
        }}
    // startIcon={
    //     <Box
    //         component="img"
    //         src="/assets/icons/providers/wallet/Wallet_Provider_Metamask.svg"
    //     />
    // }
    // endIcon={
    //     <Iconify icon={isSelected ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
    // }
    >
        Connect Wallet
    </Button>
}
