/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDraftAssetERC20, IDraftAssetERC721, IProductAssetERC20, IProductAssetERC721 } from "src/types/Asset";
import { CreatorMode, SelectionArea } from "src/types/Creator";
import { DraftDocument, IDraftDocument } from "src/types/Draft";
import { SupportedNetworks } from "src/types/Network";
import { ProductDesign } from "src/types/ProductDesign";
import { Asset, Product } from "src/types/types";

type IProduct = Product.Firebase.IProduct;

interface CreatorState {
    draftDoc: IDraftDocument | null;
    product: Product.Firebase.IProduct | null;
    selectionArea: SelectionArea;
    mode: CreatorMode;
    assetsTransferred: number;
    // productSKU: string; // Needed only if the mode was change to creation
}

const initialState: CreatorState = {
    draftDoc: null,
    product: null,
    mode: CreatorMode.Draft,
    selectionArea: SelectionArea.AssetsERC20,
    assetsTransferred: 0,
    // productSKU: "",
}



const slice = createSlice({
    name: 'creator',
    initialState,
    reducers: {
        openDraft: (state, action: PayloadAction<{ draftDoc: DraftDocument }>) => {
            state.draftDoc = action.payload.draftDoc;
            state.mode = CreatorMode.Draft;
            state.selectionArea = SelectionArea.AssetsERC20;
            state.product = null;
        },
        openProduct: (state, action: PayloadAction<{ product: IProduct }>) => {
            state.product = action.payload.product;
            state.mode = CreatorMode.Creation;
        },
        reset: (state, action: PayloadAction<{}>) => initialState,
        updateSelectionArea: (state, action: PayloadAction<SelectionArea>) => {
            state.selectionArea = action.payload;
        },
        // eslint-disable-next-line consistent-return
        updateAssetERC20: (state, action: PayloadAction<{ asset: IDraftAssetERC20 }>) => {
            if (!state.draftDoc) return console.error("draft not found in local cache");
            const draftAssetsERC20 = state.draftDoc.draft.assets.ERC20;
            const updatedAsset = action.payload.asset;
            const quantity = state.draftDoc.draft.quantity;
            let found = false;

            for (let index = 0; index < draftAssetsERC20.length && !found; index += 1) {
                const asset = draftAssetsERC20[index];
                if (asset.id === updatedAsset.id) {
                    found = true;
                    asset.giftoinAmount = updatedAsset.giftoinAmount;
                    asset.productAmount = updatedAsset.giftoinAmount * quantity;
                }
            }

            // If not found in current assets in draft -> need to add it as a new asset
            if (!found) {
                draftAssetsERC20.push(updatedAsset);
            }
        },
        deleteAssetERC20: (state, action: PayloadAction<{ coinId: number }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                const { coinId } = action.payload;
                state.draftDoc.draft.assets.ERC20 = state.draftDoc.draft.assets.ERC20.filter(asset => asset.id !== coinId)
            }
        },
        addAssetERC721: (state, action: PayloadAction<{ asset: IDraftAssetERC721 }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                // console.log("current", )
                // Use current for getting the current state

                // Push the new asset 

                // new set = ? 
                const { asset } = action.payload;

                const newAssets = state.draftDoc.draft.assets.ERC721.concat();
                newAssets.push(asset);

                state.draftDoc.draft.assets.ERC721 = newAssets;

                // Need to auto set the quantity to 1 for erc721
                state.draftDoc.draft.quantity = 1;
            }
        },
        deleteAssetERC721: (state, action: PayloadAction<{ contractAddress: string, tokenId: string }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                const { contractAddress, tokenId } = action.payload;

                // eslint-disable-next-line array-callback-return

                const newAssets: IDraftAssetERC721[] = [];

                // eslint-disable-next-line array-callback-return
                state.draftDoc.draft.assets.ERC721.map(asset => {

                    // Found the asset we want to delete -> don't include it in the new array
                    if (asset.tokenId === tokenId && asset.contractAddress === contractAddress) {
                        return;
                    }
                    newAssets.push(asset);

                }
                )
                state.draftDoc.draft.assets.ERC721 = newAssets;
            }
        },
        // eslint-disable-next-line consistent-return
        updateProductAssetERC20: (state, action: PayloadAction<{ asset: Asset.Firebase.IERC20 }>) => {
            if (!state.product) return console.error("product not found in local cache");
            const updatedAsset = action.payload.asset;
            const productAssetsERC20 = state.product?.assets.ERC20;

            let found = false;

            for (let index = 0; index < productAssetsERC20.length && !found; index += 1) {
                const asset = productAssetsERC20[index];
                if (asset.id === updatedAsset.id) {
                    productAssetsERC20[index] = updatedAsset;
                    found = true;
                }
            }

        },
        // eslint-disable-next-line consistent-return
        updateProductAssetERC721: (state, action: PayloadAction<{ asset: Asset.Firebase.IERC721 }>) => {
            if (!state.product) return console.error("product not found in local cache");
            const updatedAsset = action.payload.asset;
            const productAssetsERC721 = state.product?.assets.ERC721;

            let found = false;

            for (let index = 0; index < productAssetsERC721.length && !found; index += 1) {
                const asset = productAssetsERC721[index];
                if (asset.contractAddress === updatedAsset.contractAddress && asset.tokenId === updatedAsset.tokenId) {
                    productAssetsERC721[index] = updatedAsset;
                    found = true;
                }
            }
        },
        incrementQuantity: (state, action: PayloadAction<{ amount: number }>) => {
            if (!state.draftDoc?.draft) return console.error("draft not found in local cache");
            const newAmount = (state.draftDoc?.draft.quantity ?? 0) + action.payload.amount;
            state.draftDoc.draft.quantity = newAmount;

            // Update the quantity for every asset 
            const draftAssetsERC20 = state.draftDoc.draft.assets.ERC20;

            for (let index = 0; index < draftAssetsERC20.length; index += 1) {
                const asset = draftAssetsERC20[index];
                asset.productAmount = asset.giftoinAmount * newAmount;
            }

        },
        updateNetwork: (state, action: PayloadAction<{ network: SupportedNetworks }>) => {
            if (!state.draftDoc?.draft) return console.error("draft not found in local cache");
            const currentNetwork = state.draftDoc.draft.network;
            const newNetwork = action.payload.network;

            // Make sure the network is indeed different than the current one
            if (currentNetwork !== newNetwork) {
                // Reset all assets - because they belong to the previous network
                state.draftDoc.draft.assets.ERC20 = [];
                state.draftDoc.draft.assets.ERC721 = [];

                // Update the network to the new one
                state.draftDoc.draft.network = newNetwork;
            }
        },
        updateDescription: (state, action: PayloadAction<{ description: string }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.draftDoc.draft.metadata.description = action.payload.description;
            }
        },
        updateCategory: (state, action: PayloadAction<{ category: string }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.draftDoc.draft.metadata.category = action.payload.category;
            }
        },
        updateEndDate: (state, action: PayloadAction<{ endDate: number }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.draftDoc.draft.EOL = action.payload.endDate;
            }
        },
        updateDraftName: (state, action: PayloadAction<{ name: string }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.draftDoc.draft.metadata.name = action.payload.name;
            }
        },
        // updateImages: (state, action: PayloadAction<{ url: string, index: number }>) => {
        //     if (!state.draftDoc) {
        //         console.error("draft not found in local cache")
        //     }
        //     else {
        //         state.draftDoc.draft.metadata.images[action.payload.index] = action.payload.url;
        //     }
        // },
        updateDesign: (state, action: PayloadAction<{ images: string[], design: Product.Firebase.Enums.Design }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.draftDoc.draft.metadata.images = action.payload.images;

                // ! Currently hard coded only default -> in the future if required save all other types of product design
                state.draftDoc.draft.metadata.design = Product.Firebase.Enums.Design.Default;

            }
        },
        updateId: (state, action: PayloadAction<{ draftId: string }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.draftDoc.id = action.payload.draftId;
            }
        },
        updateAssetsTransferred: (state, action: PayloadAction<{ asset: number }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.assetsTransferred += action.payload.asset;
            }
        },
        resetAssetsTransferred: (state, action: PayloadAction<{ reset: boolean }>) => {
            if (!state.draftDoc) {
                console.error("draft not found in local cache")
            }
            else {
                state.assetsTransferred = 0;
            }
        },
        // updateProductSKU: (state, action: PayloadAction<{ productSKU: string }>) => {
        //     if (!state.draftDoc) {
        //         console.error("draft not found in local cache")
        //     }
        //     else {
        //         state.productSKU = action.payload.productSKU;
        //     }
        // },

    }
}
)


export const {
    openDraft,
    updateDescription,
    updateCategory,
    updateEndDate,
    updateDraftName,
    updateDesign,
    updateId,
    openProduct,
    updateSelectionArea,
    updateNetwork,
    addAssetERC721,
    updateProductAssetERC721,
    incrementQuantity,
    updateProductAssetERC20,
    updateAssetERC20,
    deleteAssetERC20,
    deleteAssetERC721,
    reset,
    updateAssetsTransferred,
    // updateProductSKU,
    resetAssetsTransferred,
} = slice.actions;


export default slice.reducer;



