import { Network } from '../types/Network';
import { ReturnObject } from '../types/org-logger/ReturnObject';
import { baseStudioServer } from './baseStudioServer';

const baseUrl = 'api/networks';

export const networkApi = baseStudioServer.injectEndpoints({
    endpoints: (builder) => ({
        network: builder.query<Network[], { chainId?: number, networkName?: string }>({
            query: (args) => {
                const { chainId, networkName } = args;
                return {
                    url: baseUrl,
                    params: { chainId, networkName }
                };
            },
            providesTags: ['Network'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as Network[],


        }),
    }),
})

export const {
    useNetworkQuery,
} = networkApi;