import { ReturnObject } from 'src/types/org-logger/ReturnObject';
import { UserType } from 'src/types/User';
import { baseStudioServer } from './baseStudioServer';

const baseUrl = "auth/";

export const userAuth = baseStudioServer.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<{ userType: string, walletAddress: string }, { emailAddress: string }>({
            query: (args) => ({
                url: `${baseUrl}login`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['User'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as { userType: string, walletAddress: string, emailAddress: string },


        }),
        register: builder.mutation<{ userType: string, walletAddress: string }, { name: string, walletAddress: string, emailAddress: string }>({
            query: (args) => ({
                url: `${baseUrl}register`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['User'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg,
            ) =>
                // Expect to return user role data based on user type
                // if (arg.userType === UserType.Reseller)
                //     return baseQueryReturnValue.data as { userType: string, walletAddress: string, emailAddress: string };

                baseQueryReturnValue.data as { userType: string, walletAddress: string, emailAddress: string }
            ,


        }),
    }),
})

export const {
    useRegisterMutation,
} = userAuth;