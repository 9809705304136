import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { SupportedNetworks } from 'src/types/Network';
import { dispatch, useAppSelector } from 'src/redux/store';
import { updateNetwork } from 'src/redux/slices/creatorSlice';
import Label from 'src/components/label';



export interface Props {
  disabled: boolean;
  name: string;
}


export default function ChainDropDown({disabled, name }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const curNetwork = useAppSelector(state => state.creator.draftDoc?.draft.network);
  
  const Supported = [
    { icon: '../assets/icons/networks/ethereum.svg', network: SupportedNetworks.Ethereum },
    { icon: '../assets/icons/networks/goerli.svg', network: SupportedNetworks.Goerli },
    { icon: '../assets/icons/networks/mumbai.svg', network: SupportedNetworks.Mumbai },
    { icon: '../assets/icons/networks/polygon.svg', network: SupportedNetworks.Polygon },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleSelect = (chain: SupportedNetworks) => {
        dispatch(updateNetwork({ network: chain }));
        handleClose();
    }

  return (
    <Box sx={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    }}>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled}
        color='secondary'
      >
        <Label color='secondary' sx={{height: 35, p: 2}}>
            <Stack direction='row' spacing={2}>
                <img src={`../assets/icons/networks/${curNetwork?.toLocaleLowerCase()}.svg`} alt='thumbnail' width={20}/>
                <Typography variant="h6" paragraph>{curNetwork}</Typography>
            </Stack>
        </Label>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {
          Supported.map((item) => item.network !== curNetwork ? 
          <MenuItem onClick={() => handleSelect(item.network)}>
            <Stack direction='row' spacing={2} sx={{width: 130, justifyContent: 'center'}}>
                <img src={`../assets/icons/networks/${item.network?.toLocaleLowerCase()}.svg`} alt='thumbnail' width={18}/>
                <Typography variant="h6" paragraph>{item.network}</Typography>
            </Stack>
          </MenuItem> 
          : ''
          )
        }
      </Menu>
    </Box>
  );
}