import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { draftsApi } from "src/api/draftsApi";
import { useAppDispatch } from "src/redux/store";
import { PATH_STUDIO } from "src/routes/paths";
import { IDraftDocument } from "src/types/Draft";
import { openDraft as openDraftRedux } from '../../redux/slices/creatorSlice';

export default function useOpenDraft() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    // Fetch draft document from server -> save it to creatorSlice -> switch page to creator studio
    async function openDraft(draftId: string) {
        // Fetch draft from server

        const res: any = await dispatch(draftsApi.endpoints.getSingleDraft.initiate({ draftId }));
        if (res.error) {
            return enqueueSnackbar(`Error fetching draft from server - ${res.error?.message}`, { variant: 'error' })
        }

        if (res.data) {
            const draftDoc = res.data as IDraftDocument;

            await dispatch(openDraftRedux({ draftDoc }));
            navigate(PATH_STUDIO.creator, { replace: true });
            return true;
        }
        return enqueueSnackbar("Error fetching draft from server", { variant: 'error' })

    }
    return { openDraft };

}