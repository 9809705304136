import { IDraft, IDraftDocument } from "src/types/Draft";
import { ReturnObject } from "../types/org-logger/ReturnObject";
import { baseStudioServer } from "./baseStudioServer";
import { IStudioServerCreateDraft } from "./types";

const baseUrl = "api/studio/drafts"

export const draftsApi = baseStudioServer.injectEndpoints({
    endpoints: (builder) => ({
        getDrafts: builder.query<IDraftDocument[], {}>({
            query: (args) => ({
                url: `${baseUrl}/get-all-drafts`,
                params: { ...args }
            }),
            providesTags: ['Drafts'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as IDraftDocument[],
            keepUnusedDataFor: 0,

        }),
        getSingleDraft: builder.query<IDraftDocument, { draftId: string }>({
            query: (args) => ({
                url: `${baseUrl}/get-single-draft`,
                params: { ...args }
            }),
            providesTags: ['Drafts'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as IDraftDocument,
            keepUnusedDataFor: 0,

        }),
        createDraft: builder.mutation<{ draftId: string }, { draft: IDraft }>({
            query: (args) => ({
                url: `${baseUrl}`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['Drafts'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as { draftId: string },


        }),
        updateDraft: builder.mutation<{ draftId: string }, { product: IDraft, draftId: string }>({
            query: (args) => ({
                url: `${baseUrl}`,
                method: 'PATCH',
                body: { ...args },
            }),
            invalidatesTags: ['Drafts'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as { draftId: string },


        }),
        deleteDraft: builder.mutation<boolean, { draftId: string }>({
            query: (args) => ({
                url: `${baseUrl}`,
                method: 'DELETE',
                params: { ...args },
            }),
            invalidatesTags: ['Drafts'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as boolean,
            // @link https://redux-toolkit.js.org/rtk-query/usage/mutations
            // Pick out errors and prevent nested properties in a hook or selector
            // transformErrorResponse: (
            //     response: { status: string | number },
            //     meta,
            //     arg
            // ) => response.status,

            // Update the drafts in RTK query cache -> the most simple way, just refetch all drafts after successful delete
            // ! Does not delete it from the draftSlice !
            async onQueryStarted({ draftId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    if (data) {
                        dispatch(draftsApi.endpoints.getDrafts.initiate({}));

                    }
                } catch { /* empty */ }
            },



        }),
    }),
})

export const {
    useGetDraftsQuery,
} = draftsApi;