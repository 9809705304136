import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
// components
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
//

// ----------------------------------------------------------------------

// @mui
import { Stack, Box, Button, ButtonProps, Typography, Divider, MenuItem } from '@mui/material';
import SvgColor from 'src/components/svg-color';
import { useNavigate } from 'react-router';
import WalletButton from 'src/sections/header/WalletButton';
import { store, useAppSelector } from 'src/redux/store';
import { useAppDispatch } from 'src/redux/hooks';
import { web3Disconnected } from 'src/redux/slices/authSlice';
import useUpdateDraft from 'src/hooks/creator/useUpdateDraft';
import useCreateNewDraft from 'src/hooks/creator/useCreateNewDraft';
import { updateOpenFilesDialog } from 'src/redux/slices/modalsSlice';

// ----------------------------------------------------------------------

interface Props extends ButtonProps {
    children?: React.ReactNode;
    isSelected: boolean;
}





export default function FilePopover() {
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const navigate = useNavigate();
    const { isAuthenticatedWeb3 } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };
    const { isLoading, updateDraft } = useUpdateDraft();
    const { createNewDraft } = useCreateNewDraft();
    const handleSave = () => {
        const { draftDoc } = store.getState().creator;

        if (draftDoc) {
            updateDraft(draftDoc);
        }
    }

    const handleNew = () => {
        createNewDraft();
    }


    const handleOpen = () => {
        dispatch(updateOpenFilesDialog({ show: true }));

    }

    const OPTIONS = [
        {
            label: 'New',
            action: handleNew,
        },
        {
            label: 'Open',
            action: handleOpen,
        },
        {
            label: 'Save',
            action: handleSave,
        },
    ];


    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleClickItem = (action: VoidFunction) => {
        handleClosePopover();
        action();
    };





    const handleWalletDisconnect = async () => {
        try {
            handleClosePopover();
            await dispatch(web3Disconnected({}));
            return true;

        } catch (error) {
            console.log(error);
            return false;
        }
    }






    return (
        <>

            {/* <WalletButton isSelected={!!openPopover} onClick={handleOpenPopover} /> */}
            <Button
                variant="soft"
                onClick={handleOpenPopover}
                color="inherit"
                sx={{
                    // textTransform: 'unset',
                    // color: 'text.secondary',
                    width: { xs: 'auto', md: 'auto' },
                    justifyContent: 'flex-start',
                    fontWeight: 'fontWeightMedium',
                }}
                endIcon={
                    <Iconify icon={openPopover ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
                }
            >
                File
            </Button>
            <MenuPopover open={openPopover} onClose={handleClosePopover} >
                <Stack >
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClickItem(option.action)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
            </MenuPopover>
        </>
    );
}
