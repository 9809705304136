// ----------------------------------------------------------------------
// @mui
import { Alert, Button, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import useLoginWithGoogle from 'src/hooks/auth/useLoginWithGoogle';
import { useAppDispatch } from 'src/redux/hooks';
// components
import Iconify from '../../components/iconify';


export default function AuthWithSocial() {
  // const { loginWithGithub, loginWithTwitter } = useAuthContext();
  const [errorLogin, setErrorLogin] = useState<undefined | string>(undefined);
  const dispatch = useAppDispatch();

  const { loginWithGoogle } = useLoginWithGoogle();

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();


    } catch (error) {
      console.log(error)
      setErrorLogin(error.message);
    }
  };






  // const handleGithubLogin = async () => {
  //   try {
  //     if (loginWithGithub) {
  //       loginWithGithub();
  //     }
  //     console.log('GITHUB LOGIN');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleTwitterLogin = async () => {
  //   try {
  //     if (loginWithTwitter) {
  //       loginWithTwitter();
  //     }
  //     console.log('TWITTER LOGIN');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div>

      <Stack direction="row" justifyContent="flex-start" spacing={2}>
        <Button variant='soft' onClick={handleGoogleLogin} startIcon={<Iconify icon="eva:google-fill" color="#DF3E30" />}>
          Login With Google

        </Button>
        {/* <IconButton color="inherit" onClick={handleGithubLogin}>
          <Iconify icon="eva:github-fill" />
        </IconButton>

        <IconButton onClick={handleTwitterLogin}>
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
        </IconButton> */}
      </Stack>
      {errorLogin && <Alert severity="error">{errorLogin}</Alert>}

    </div>
  );
}
