// @mui
import { alpha, GlobalStyles as MUIGlobalStyles, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {

  const theme = useTheme();

  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        // '*::-webkit-scrollbar': {
        //   width: '15px'
        // },
        // '*::-webkit-scrollbar-track': {
        //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        // },
        // '*::-webkit-scrollbar-thumb': {
        //   backgroundColor: alpha(theme.palette.grey[600], 0.48),
        //   outline: '1px solid slategrey'
        // }

      }}
    />
  );

  return inputGlobalStyles;
}
